import http from "@/api/http.js";

function listAll() {
  return http({
    url: "/DmsActivity/List",
    method: "get",
  });
}

function create(payload) {
  return http({
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: "/DmsActivity/Create",
    method: "post",
    data: payload,
  });
}

export default {
  listAll,
  create,
};
