<template>
  <div class="activity-create-form">
    <a-form
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :form="createForm"
    >
      <a-form-item label="活动主题">
        <a-input v-model="title" placeholder="请填写活动主题" />
      </a-form-item>
      <a-form-item label="活动地址">
        <a-input v-model="address" placeholder="请填写活动地址" />
      </a-form-item>
      <a-form-item label="开始时间">
        <a-date-picker
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          @change="
            (date, dateString) => {
              this.startTime = dateString;
            }
          "
        />
      </a-form-item>
      <a-form-item label="结束时间">
        <a-date-picker
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          @change="
            (date, dateString) => {
              this.endTime = dateString;
            }
          "
        />
      </a-form-item>
      <a-form-item label="图片上传">
        <a-upload
          :file-list="fileList"
          :remove="onRemove"
          :before-upload="beforeUpload"
        >
          <a-button> <a-icon type="upload" /> 选择文件 </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit" @click="onSubmit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import DmsActivityApi from "@/api/dms/dms-activity.js";

export default {
  name: "ActivityCreateForm",
  data() {
    return {
      createForm: {},
      title: "",
      address: "",
      startTime: "",
      endTime: "",
      fileList: [],
    };
  },
  mounted: function () {},
  methods: {
    onRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    onSubmit: function () {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("address", this.address);
      formData.append("startTime", new Date(this.startTime));
      formData.append("endTime", new Date(this.endTime));
      this.fileList.forEach((file) => {
        formData.append("photos", file);
      });
      DmsActivityApi.create(formData).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          Object.assign(this.$data, this.$options.data());
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
