import http from "@/api/http.js";

function listByActivityId(activityId) {
  return http({
    url: "/DmsActivityCheckin/ListByActivityId/" + activityId,
    method: "get",
  });
}

export default {
  listByActivityId,
};
