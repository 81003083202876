import http from "@/api/http.js";

function countByActivityId(activityId) {
  return http({
    url: "/DmsActivityVisit/CountByActivityId/" + activityId,
    method: "get",
  });
}

export default {
  countByActivityId,
};
