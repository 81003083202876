<template>
	<div class="dms-activity">
		<div class="header">
			<a-breadcrumb style="margin-bottom: 20px">
				<a-breadcrumb-item>分销系统</a-breadcrumb-item>
				<a-breadcrumb-item>销售活动</a-breadcrumb-item>
			</a-breadcrumb>
			<a-row>
				<a-col :span="18">
					<a-button type="primary" icon="plus" @click="activityCreateModalVisible = true">创建活动</a-button>
				</a-col>
				<a-col :span="6">
					<a-input-search v-model="dataSourceSearchInput" placeholder="请输入任何想搜索的内容" allowClear
						@search="handleDataSourceSearch" />
				</a-col>
			</a-row>
		</div>
		<div class="body">
			<a-table rowKey="id" :data-source="getCurrentDataSourceList" :pagination="paginationOptions" @change="
          (pagination) => {
            paginationOptions.current = pagination.current;
            paginationOptions.pageSize = pagination.pageSize;
          }
        " :scroll="{ x: 1200 }" bordered size="small">
				<a-table-column key="index" title="序号" :width="60" data-index="index">
					<template slot-scope="text, record, index">
						<span>{{
              (paginationOptions.current - 1) * paginationOptions.pageSize +
              parseInt(index) +
              1
            }}</span>
					</template>
				</a-table-column>
				<a-table-column key="id" title="ID" :width="200" data-index="id" />
				<a-table-column key="title" title="主题" :width="200" data-index="title" />
				<a-table-column key="address" title="地址" :width="200" data-index="address" />
				<a-table-column key="startTime" title="开始时间" :width="150" data-index="startTime" />
				<a-table-column key="endTime" title="结束时间" :width="150" data-index="endTime" />
				<a-table-column key="checkinUrl" title="报名地址" :width="200" data-index="checkinUrl" />
				<a-table-column title="报名页面图片" :width="120">
					<template slot-scope="text">
						<a @click="handlePhotosShowBtnClick(text)">查看详情</a>
					</template>
				</a-table-column>
				<a-table-column key="visitCount" title="活动流量" :width="100" data-index="visitCount" />
				<a-table-column title="报名人数" :width="100">
					<template slot-scope="text">
						<a @click="
                dataSourceCurrent = text;
                checkinModalVisible = true;
              ">{{ text.checkinList ? text.checkinList.length : 0 }}</a>
					</template>
				</a-table-column>
				<a-table-column key="createTime" title="创建时间" :width="200" data-index="createTime" />
				<a-table-column key="createBy" title="创建人" :width="200" data-index="createBy" />
			</a-table>
		</div>
		<div class="footer"></div>

		<a-modal title="报名页面图片" :visible="photosModalVisible" :footer="null" @cancel="photosModalVisible = false"
			closable>
			<a-row v-if="photosUrlList.length > 0">
				<a-col :span="8" v-for="(item, index) in photosUrlList" :key="index">
					<a-card hoverable style="width: 100%">
						<img slot="cover" alt="example" :src="item" @click="thisWindow.open(item)" />
					</a-card>
				</a-col>
			</a-row>
			<div v-else>
				<a-empty />
			</div>
		</a-modal>

		<a-modal v-model="activityCreateModalVisible" title="请填写以下信息" :footer="null" closable>
			<activity-create-form @onSubmit="handleActivityCreateFormSubmit" />
		</a-modal>

		<a-modal v-model="checkinModalVisible" title="报名人员名单" :footer="null" :width="screenSpec.width - 2 * 100"
			closable>
			<activity-checkin-table :dataSourceList="dataSourceCurrent.checkinList" />
		</a-modal>
	</div>
</template>

<script>
	import PaginationOptions from "@/options/pagination.js";
	import FiltrationOptions from "@/options/filtration.js";
	import DmsActivityApi from "@/api/dms/dms-activity.js";
	import DmsActivityVisitApi from "@/api/dms/dms-activity-visit.js";
	import DmsActivityCheckinApi from "@/api/dms/dms-activity-checkin.js";
	import ActivityCheckinTable from "./components/ActivityCheckinTable.vue";
	import ActivityCreateForm from "./components/ActivityCreateForm.vue";
	import {
		mapState
	} from "vuex";

	export default {
		name: "DmsActivity",
		components: {
			ActivityCheckinTable,
			ActivityCreateForm,
		},
		data() {
			return {
				thisWindow: window,
				paginationOptions: PaginationOptions,
				filtrationOptions: FiltrationOptions,
				queryType: "1",
				queryDate: [],
				dataSourceList: [],
				dataSourceCurrent: {},
				dataSourceSearchInput: "",
				dataSourceSearchList: [],
				dataSourceSearchValid: false,
				photosUrlList: [],
				photosModalVisible: false,
				checkinModalVisible: false,
				activityCreateModalVisible: false,
			};
		},
		computed: {
			...mapState({
				screenSpec: (state) => state.screenSpec,
			}),
			getCurrentDataSourceList: function() {
				return this.dataSourceSearchValid ?
					this.dataSourceSearchList :
					this.dataSourceList;
			},
		},
		watch: {
			dataSourceSearchInput: function(val) {
				if (val === null || val === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
				}
			},
		},
		mounted: function() {
			this.fetchActivityList();
		},
		methods: {
			fetchActivityList: function() {
				DmsActivityApi.listAll().then((response) => {
					if (response.status === "SUCCESS") {
						if (this.dataSourceList.length === 0) this.$message.success("无数据");
						this.dataSourceList = response.body;
						this.dataSourceList.forEach((item) => {
							this.fetchActivityVisitCount(item);
							this.fetchActivityCheckinList(item);
						});
					} else {
						this.dataSourceList = [];
					}
				});
			},
			fetchActivityVisitCount: function(item) {
				DmsActivityVisitApi.countByActivityId(item.id).then((response) => {
					this.$set(item, "visitCount", response.body || 0);
				});
			},
			fetchActivityCheckinList: function(item) {
				DmsActivityCheckinApi.listByActivityId(item.id).then((response) => {
					this.$set(item, "checkinList", response.body || []);
				});
			},
			handleTableChange(pagination) {
				this.paginationOptions.current = pagination.current;
				this.paginationOptions.pageSize = pagination.pageSize;
			},
			handleDataSourceSearch: function(value) {
				if (value === null || value === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
					return;
				}
				this.dataSourceSearchList = this.dataSourceList.filter((item) => {
					return JSON.stringify(item).indexOf(value.trim()) !== -1;
				});
				this.dataSourceSearchValid = true;
			},
			handlePhotosShowBtnClick: function(payload) {
				this.photosUrlList = [];
				const temp = JSON.parse(payload.photos);
				temp.forEach((item) => {
					if (item.url) this.photosUrlList.push(item.url);
				});
				this.photosModalVisible = true;
			},
			handleActivityCreateFormSubmit: function(response) {
				this.activityCreateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchActivityList();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		margin-bottom: 20px;
	}
</style>
