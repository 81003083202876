<template>
  <div class="activity-checkin-table">
    <div class="header">
      <a-row>
        <a-col :span="18">
          <a-button
            type="default"
            icon="export"
            style="margin-left: 10px"
            :disabled="dataSourceList.length === 0"
            @click="handleDataSourceExport"
            >数据导出</a-button
          >
        </a-col>
        <a-col :span="6">
          <a-input-search
            v-model="dataSourceSearchInput"
            placeholder="请输入任何想搜索的内容"
            allowClear
            @search="handleDataSourceSearch"
          />
        </a-col>
      </a-row>
    </div>
    <div class="body">
      <a-table
        rowKey="id"
        :data-source="getCurrentDataSourceList"
        :pagination="paginationOptions"
        @change="
          (pagination) => {
            paginationOptions.current = pagination.current;
            paginationOptions.pageSize = pagination.pageSize;
          }
        "
        :scroll="{ x: 1200 }"
        bordered
        size="small"
      >
        <a-table-column key="index" title="序号" :width="60" data-index="index">
          <template slot-scope="text, record, index">
            <span>{{
              (paginationOptions.current - 1) * paginationOptions.pageSize +
              parseInt(index) +
              1
            }}</span>
          </template>
        </a-table-column>
        <a-table-column key="id" title="ID" :width="200" data-index="id" />
        <a-table-column
          key="name"
          title="姓名"
          :width="120"
          data-index="name"
        />
        <a-table-column
          key="phone"
          title="手机号"
          :width="150"
          data-index="phone"
        />
        <a-table-column
          key="company"
          title="工作单位"
          :width="200"
          data-index="company"
        />
        <a-table-column
          key="createTime"
          title="报名时间"
          :width="150"
          data-index="createTime"
        />
      </a-table>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import PaginationOptions from "@/options/pagination.js";
import FiltrationOptions from "@/options/filtration.js";
import ExportUtil from "@/utils/export.js";
import { mapState } from "vuex";

export default {
  name: "ActivityCheckinTable",
  props: ["dataSourceList"],
  data() {
    return {
      paginationOptions: PaginationOptions,
      filtrationOptions: FiltrationOptions,
      queryType: "1",
      queryDate: [],
      // dataSourceList: [],
      dataSourceCurrent: {},
      dataSourceSearchInput: "",
      dataSourceSearchList: [],
      dataSourceSearchValid: false,
    };
  },
  computed: {
    ...mapState({}),
    getCurrentDataSourceList: function () {
      return this.dataSourceSearchValid
        ? this.dataSourceSearchList
        : this.dataSourceList;
    },
  },
  watch: {
    dataSourceSearchInput: function (val) {
      if (val === null || val === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
      }
    },
  },
  mounted: function () {},
  methods: {
    handleTableChange(pagination) {
      this.paginationOptions.current = pagination.current;
      this.paginationOptions.pageSize = pagination.pageSize;
    },
    handleDataSourceSearch: function (value) {
      if (value === null || value === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
        return;
      }
      this.dataSourceSearchList = this.dataSourceList.filter((item) => {
		  return JSON.stringify(item).indexOf(value.trim()) !== -1;
      });
      this.dataSourceSearchValid = true;
    },
    handleDataSourceExport: function () {
      const columns = [
        {
          title: "姓名",
          key: "name",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "工作单位",
          key: "company",
        },
        {
          title: "报名时间",
          key: "createTime",
        },
      ];

      // 整理数据
      var list = [];
      for (let i = 0, j = this.dataSourceList.length; i < j; i++) {
        const item = {};
        for (let m = 0, n = columns.length; m < n; m++) {
          item[columns[m].key] = this.dataSourceList[i][columns[m].key];
        }
        list.push(item);
      }

      ExportUtil.export2Excel(columns, list, "报名人员信息表");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 20px;
}
</style>
